import * as signalR from '@microsoft/signalr';
import { isAppUnloaded } from '.';
import { DisplayBoardWelcome, MeetingData, Recipient } from './api';
import { Person } from './model';
import { backoff, delay } from './utils/misc';
import { createPromiseSource } from './utils/promise';

export type PushNotification = {
   update: {
      meeting: MeetingData
   }
} | {
   locationTag: {
      userEmail: string
      locationEmail: string
      token: string
   }
} | {
   visitorTag: DisplayBoardWelcome
} | {
   organiserNotified: Recipient
} | {
   resourceAvailabilityChange: {
      emailAddress: string
   }
} | {
   delete: {
      email: string
      escapedId: string
      id: string
   }
} | {
   reload: {
      organisationId: string
   }
} | {
   reccurrence: {
      email: string
      id: string
   }
} | 'reconnecting' | 'reconnected'

let connection: signalR.HubConnection
let isConnected: boolean
let connected = createPromiseSource()
let wasConnected = false

export default function pushNotifications(onEvent: (ev: PushNotification) => void) {
   connection = new signalR.HubConnectionBuilder()
      .withUrl('/push')
      .configureLogging(process.env.NODE_ENV === 'development' ? signalR.LogLevel.Error : signalR.LogLevel.None)
      .build()

   connection.on('AddOrUpdated', (meeting: MeetingData) => {
      log('info', meeting)
      onEvent({ update: { meeting } })
   })

   connection.on('MeetingDeleted', (email: string, escapedId: string, id: string) => {
      log('info', 'delete', email, escapedId, id)
      onEvent({
         delete: { email, escapedId, id },
      })
   })

   connection.on('ResourceAvailabilityChange', (emailAddress: string) => {
      log('info', 'resourceAvailabilityChange', emailAddress)
      onEvent({
         resourceAvailabilityChange: { emailAddress },
      })
   })

   connection.on('RecurrenceAddOrUpdated', (email: string, recurrenceId: string) => {
      log('info', 'reccurrence', email, recurrenceId)
      onEvent({
         reccurrence: { email, id: recurrenceId },
      })
   })

   let retries = 0
   let reconnecting = false

   connection.on('LocationTagEvent', (userEmail: string, locationEmail: string, token: string) => {
      log('info', userEmail, locationEmail, token)
      onEvent({ locationTag: { userEmail, locationEmail, token } })
   })

   connection.on('VisitorTagEvent', (person: Person, meetings: MeetingData[]) => {
      onEvent({ visitorTag: { person, meetings } })
   })

   connection.on('OrganiserNotifiedEvent', (organiser: Recipient) => {
      onEvent({ organiserNotified: organiser })
   })

   connection.on('ReloadOrganisation', (organisationId: string) => {
      log('info', 'reload', organisationId)
      onEvent({ reload: { organisationId } })
   })

   connection.onclose(err => {
      log(err ? 'error' : 'info', 'connection closed', err)

      isConnected = false
      if (wasConnected) {
         connected = createPromiseSource()
      }

      if (!isAppUnloaded()) {
         reconnect()
      }
   })

   reconnect()
   return () => connection.stop()

   async function reconnect() {
      if (reconnecting) { return }
      reconnecting = true

      await connection.stop().catch(() => { })

      if (wasConnected) {
         onEvent('reconnecting')
      }

      for (; ;) {
         try {
            log('info', 'connecting...')
            await delay(backoff(retries++))
            await connection.start()
            break
         } catch (e) {
            log('error', 'error connecting', e)
         }
      }

      try {
         if (wasConnected) {
            onEvent('reconnected')
            await connection.stop().catch(() => { })
            return
         }

         reconnecting = false
         retries = 0
         wasConnected = true
         isConnected = true
         connected.resolve()

         startHeartbeat()

         log('info', 'connected')
      } catch (e) {
         log('error', 'error subscribing', e)
         reconnect()
      }
   }
}

export async function getServerTimestamp(): Promise<number | undefined> {
   try {
      if (!isConnected) { return }
      return await connection.invoke('GetServerTimestamp')
   } catch (e) {
      console.error(e)
   }
}

export async function reconnect() {
   if (isConnected) {
      isConnected = false
      wasConnected = false
      connected = createPromiseSource()

      try {
         await connection.stop()
      } catch (e) {
         log('error', 'error stopping the connection', e)
      }
   }
   await connected.promise
}

export async function subscribe(...emails: string[]) {
   await connected.promise
   await connection.invoke('Subscribe', emails)
}

export async function unsubscribe(...emails: string[]) {
   await connected.promise
   await connection.invoke('Unsubscribe', emails)
}

function startHeartbeat() {
   setInterval(sendHeartbeat, 60000)
}

function sendHeartbeat() {
   if (isConnected) {
      connection.invoke('Heartbeat')
   }
}

function log(type: 'info' | 'error', ...args: any[]) {
   // if (process.env.NODE_ENV === 'development') {
   console[type]('push', ...args)
   // }
}
