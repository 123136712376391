import moment from 'moment';
import { createAction, createStandardAction } from 'typesafe-actions';
import * as Api from '../api';
import { Agenda, AgendaItem, Attachment, AttendeeData, AttendeeType, BookingResourcesFilter, Contact, Domain, MeetingType, Nearby, Page, PermissionSet, PopupParams, PopupTypeNoParams, Recurrence, Suggestion, Team, TeamToJoin } from '../model';
import { createApiActions } from '../utils/apiActions';
import { roundStartTime, timeOfDayInHours, truncHr } from '../utils/dateUtils';
import { Meeting } from './reducers/meetings';
import { Theme } from './reducers/achieve';

export const initApp = createAction('INIT_APP');
export const loggedIn = createAction('LOGGED_IN');
export const setVersion = createAction('SET_VESION', resolve => (version: string | undefined) => resolve(version))

export const apiError = createAction('API_ERROR', resolve => (error: Error) => resolve(error));
export const closeErrorToast = createAction('CLOSE_ERROR_TOAST', resolve => (id: string) => resolve(id));

export const updateTime = createAction('UPDATE_TIME', resolve => (time?: any) => resolve(moment(time).startOf('m').valueOf()));
export const setServerTimeOffset = createStandardAction('SET_SERVER_TIME_OFFSET')<number>()

export const updateContact = createAction('UPDATE_CONTACT', resolve => (contact: Contact) => resolve(contact));
export const cleanupPersons = createAction('CLEANUP_PERSONS', resolve => (except: string[]) => resolve(except));
export const restoreLogin = createAction('RESTORE_LOGIN', resolve => (contact: Contact, organisation: Api.Organisation) => resolve({contact, organisation}));

export const setTeams = createAction('SET_TEAMS', resolve => (teams: Team[] | null) => resolve(teams))
export const setCurrentTeamsChat = createAction('SET_CURRENT_TEAMS_CHAT', resolve => (currentTeam: string[]) => resolve(currentTeam))
export const addTeam = createStandardAction('ADD_TEAM')<TeamToJoin>()
export const removeTeam = createStandardAction('REMOVE_TEAM')<TeamToJoin>()

export const fetchScheduleRequest = createAction('FETCH_SCHEDULE_REQUEST', resolve => (day: number, email: string, force?: boolean) => resolve({ day, email, force }))
export const fetchScheduleRequested = createAction('FETCH_SCHEDULE_REQUESTED', resolve => (day: number, email: string) => resolve({ day, email }))
export const fetchSchedulesResponse = createAction('FETCH_SCHEDULE_RESPONSE', resolve => (schedules: Api.SchedulesData) => resolve(schedules))

export const fetchCalendarsRequest = createAction('FETCH_CALENDARS_REQUEST',
   resolve => (start: number, end: number, email: string) => resolve({ start, end, email }))
export const fetchCalendarsResponse = createAction('FETCH_CALENDARS_RESPONSE', resolve => (email: string, schedules: Api.CalendarsData[string]) => resolve({email, schedules}))

export const delegatedLogin = createStandardAction('DELEGATED_LOGIN_REQUEST')<Api.DelegeatedLoginArgs>()
export const login = {
   request: createStandardAction('LOGIN_REQUEST')<Api.LoginArgs>(),
   response: createAction('LOGIN_RESPONSE', resolve => (payload: Api.Login) => resolve(payload)),
};

export const whoAmI = createApiActions(
   'WHO_AM_I_REQUEST',
   'WHO_AM_I_RESPONSE',
   Api.whoAmI);

export const pickLocation = createAction('PICK_LOCATION', resolve => (location: string) => resolve(location));

export const cancelMeeting = createAction('CANCEL_MEETING_REQUEST',
   resolve => (id: string, data?: Api.SecondaryLoginArgs) => resolve({ id, data }));

export const extendMeeting = createAction('EXTEND_MEETING_REQUEST', resolve => (id: string, hr: number) => resolve({ id, hr }));
export const changeMeetingResponseStatus = createAction('CHANGE_MEETING_RESPONSE_STATUS', resolve => (id: string, myself: string, comment: string|undefined, sendResponse: boolean, responseStatus: "Accepted" | "Tentative" | "Declined") => resolve({ id, myself, comment, sendResponse, responseStatus }));

type StartBookingArgs = {
   start?: number
   participants?: AttendeeData[]
   pinnedRoom?: string
   full?: boolean
   meeting?: {
      id: string
      subject: string
      bodyText: string
      agendaItems: AgendaItem[]
      agendaNotes: string
      types: MeetingType[]
      isPrivate: boolean
      permissions: PermissionSet | null
      filter: BookingResourcesFilter[]
      isRecurring: boolean
      allDay: boolean
      attachments: Attachment[]
      teamsToJoin: TeamToJoin[]
   }
   room?: string
   nearby?: Nearby
}

export const startBooking = createAction('START_BOOKING', resolve => ({ start, ...data}: StartBookingArgs) => resolve(({ start: start ?? roundStartTime(timeOfDayInHours(Date.now())), ...data })))

export const startAddResource = createStandardAction('START_ADD_RESOURCE')<Meeting>()
export const setAllDay = createAction('SET_ALL_DAY', resolve => (value: boolean) => resolve(value))
export const editBooking = createAction('EDIT_BOOKING',resolve => (meetingId: string|undefined, meetingData: Api.MeetingData|undefined) => resolve({meetingId, meetingData}));
export const endBooking = createAction('END_BOOKING', resolve => (onIdle?: boolean) => resolve(onIdle));
export const addParticipant = createAction('ADD_PARTICIPANT', resolve => (emailAddress: string, type: AttendeeType) => resolve({ emailAddress, type }));
export const removeParticipant = createAction('REMOVE_PARTICIPANT', resolve => (participant: Contact) => resolve(participant));
export const setAttendeeType = createAction('SET_ATTENDEE_TYPE', resolve => (attendee: string, type: AttendeeType) => resolve({ attendee, type }));
export const setBookingStart = createAction('SET_BOOKING_START', resolve => (hr: number) => resolve(truncHr(hr)));
export const setBookingDuration = createAction('SET_BOOKING_DURATION', resolve => (duration: number) => resolve(truncHr(duration)));
export const setBookingSubject = createAction('SET_BOOKING_SUBJECT', resolve => (subject: string) => resolve(subject));
export const setBookingAgenda = createAction('SET_BOOKING_AGENDA', resolve => (agenda: Agenda) => resolve(agenda));
export const setMeetingTypes = createAction('SET_MEETING_TYPES', resolve => (types: MeetingType[]) => resolve(types));
export const setMeetingPrivate = createAction('SET_MEETING_PRIVATE', resolve => (isPrivate: boolean) => resolve(isPrivate));
export const setMeetingFull = createAction('SET_MEETING_FULL', resolve => (isFull: boolean) => resolve(isFull));
export const setCreateAppointment = createAction('SET_CREATE_APPOINTMENT', resolve => (createAppointment: boolean) => resolve(createAppointment));
export const setAttachments = createAction('SET_ATTACHMENTS', resolve => (files: Attachment[]) => resolve(files))
export const setRecurrence = createAction('SET_RECURRENCE', resolve => (value: Recurrence | null) => resolve(value))
export const removeNearby = createStandardAction('REMOVE_NEARBY')<BookingResourcesFilter | null>()
export const setPermissions = createStandardAction('SET_PERMISSION')<PermissionSet | null>()
export const setAdhocOrganisation = createStandardAction('SET_ADHOC_ORGANISATION')<Api.SharedOrganisationData>()

export const editResource = createAction('EDIT_RESOURCE', resolve => (oldResource: BookingResourcesFilter | null, newResource: BookingResourcesFilter | null) => resolve({ oldResource, newResource }))
export const updateResource = createAction('UPDATE_RESOURCE', resolve => <T extends keyof BookingResourcesFilter>(value: Pick<BookingResourcesFilter, T>) => resolve(value))
export const commitResource = createAction('COMMIT_RESOURCE')
export const removeResource = createAction('REMOVE_RESOURCE')
export const rollbackResource = createAction('ROLLBACK_RESOURCE')

export const fetchSuggestionRequesting = createAction('FETCH_SUGGESTION_REQUESTING')
export const fetchSuggestionResponse = createAction('FETCH_SUGGESTION_RESPONSE', resolve => (suggestion: Suggestion[]) => resolve(suggestion))
export const acceptSuggestion = createAction('ACCEPT_SUGGESTION', resolve => (suggestion: Suggestion | null, warning?: string) => resolve({ suggestion, warning }))

export const createMeeting = createAction('CREATE_MEETING');
export const createAdhocMeeting = createAction('CREATE_ADHOC_MEETING', resolve => (room: string, duration: number, adhocOrganisationId: string|null) => resolve({ room, duration, adhocOrganisationId }));

export const updateMeetingData = createAction('UPDATE_MEETING_DATA', resolve => (meeting: Api.MeetingData) => resolve({ meeting }));
export const replaceMeetingData = createAction('REPLACE_MEETING_DATA', resolve => (oldId: string, meeting: Api.MeetingData) => resolve({ oldId, meeting }));
export const addMeetingData = createAction('ADD_MEETING_DATA',
   resolve => (email: string, meeting: Api.MeetingData) => resolve({ email, meeting }));
export const markMeetingPending = createAction('MARK_PENDING', resolve => (id: string) => resolve(id))

export const addResourcesToMeeting = createAction('ADD_RESOURCES_TO_MEETING')

export const startMeeting = createAction('START_MEETING', resolve => (id: string, email: string) => resolve({ id, email }))
export const meetingStarted = createAction('MEETING_STARTED', resolve => (id: string, email: string) => resolve({ id, email }))
export const leaveMeeting = createAction('LEAVE_MEETING', resolve => (id: string, email: string) => resolve({ id, email }))
export const meetingLeft = createAction('MEETING_LEFT', resolve => (id: string, email: string) => resolve({ id, email }))

export const removeMeeting = createAction('REMOVE_MEETING', resolve => (id: string) => resolve(id));
export const removeCalendars = createAction('REMOVE_CALENDARS', resolve => (emails: string[]) => resolve(emails));
export const removeCalendarDaysBut = createAction('REMOVE_CALENDAR_DAYS_BUT', resolve => (email: string, day: number) => resolve({ email, day }));

export const selectDate = createAction('SELECT_DATE', resolve => (time?: moment.MomentInput) => resolve(moment(time).startOf('d').valueOf()));

export const openPopup = createAction('OPEN_POPUP', resolve => (type: PopupTypeNoParams, isSubmitting: boolean = false) => resolve({ params: { type }, isSubmitting }));
export const openPopupWithParams = createAction('OPEN_POPUP', resolve => (params: PopupParams, isSubmitting: boolean = false) => resolve({ params, isSubmitting }));
export const setPopupSubmitting = createAction('SET_POPUP_SUBMITTING', resolve => (submitting: boolean = true) => resolve(submitting));
export const setPopupError = createAction('SET_POPUP_ERROR', resolve => (error: string) => resolve(error));
export const closePopup = createAction('CLOSE_POPUP');

export const lastActivity = createAction('LAST_ACTIVITY', resolve => ()=> resolve(Date.now()));

export const reconnecting = createAction('RECONNECTING', resolve => (reconnecting: boolean = true) => resolve(reconnecting));
export const reconnect = createAction('RECONNECT');

export const fetchContacts = createApiActions(
   'FETCH_CONTACTS_REQUEST',
   'FETCH_CONTACTS_RESPONSE',
   Api.fetchContacts,
);

export const secondaryExternalLogin = createAction('SECONDARY_EXTERNAL_LOGIN_REQUEST', resolve => (domain: Domain, loginHint?: string) => resolve({ domain, loginHint }))

export const secondaryLogin = {
   request: createStandardAction('SECONDARY_LOGIN_REQUEST')<Api.SecondaryLoginArgs>(),
   response: createAction('SECONDARY_LOGIN_RESPONSE', resolve => (payload: Api.SecondaryLogin) => resolve(payload)),
};

export const secondaryLogout = createAction('SECONDARY_LOGOUT');

export const logout = createAction('LOGOUT');

export const goToPage = createAction('GO_TO_PAGE', action => (page: Page, usePush?: boolean) => action({page, usePush}));

export const setSplash = createAction('SET_SPLASH', action => (splash: string | null = null) => action(splash))

export const timeoutDisplayBoard = createAction('TIMEOUT_DISPLAY_BOARD')
export const setDisplayBoardWelcome = createStandardAction('SET_DISPLAY_BOARD_WELCOME')<Api.DisplayBoardWelcome>()
export const setDisplayBoardOrganiserNotified = createStandardAction('SET_DISPLAY_BOARD_ORGANISER_NOTIFIED')<Api.Recipient>()
export const setAchieve = createAction('SET_ACHIEVE', action => (theme: Theme, dark: boolean) => action({ theme, dark }))
