import { actions } from '../store/utils';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Theme } from '../store/reducers/achieve';
import { routerActions } from 'connected-react-router';
import { RouteComponentProps } from 'react-router-dom';


function isTheme(value: Theme | null) {
   if (!value) {
      return false
   }
   return ['default', 'red', 'red2', 'blue', 'darkblue', 'smokeblue', 'gold', 'green', 'orange', 'teal', 'violet', 'lime'].includes(value.toLowerCase())
}

interface MatchParams {
   dark: string;
   theme: Theme;
 }

 type Props = RouteComponentProps<MatchParams>;

export default function AchieveApi(props: Props) {
   const dispatch = useDispatch();
   const location = useLocation();
 
   // Extract query parameters from location
   const queryParams = new URLSearchParams(location.search);
   const dark = queryParams.get('dark');
   const theme = queryParams.get('theme');
 
   // Ensure that 'theme' is a valid theme or default if not
   const selectedTheme = isTheme(theme as Theme) ? theme : 'default';
 
   // Convert 'dark' to a boolean (assumes 'dark' can be either '1' or '0')
   const isDarkMode = dark === '1';
 
   dispatch(actions.setAchieve(selectedTheme as Theme, isDarkMode));
   dispatch(routerActions.push('/'));
 
   return null;
}
