import { connect } from 'react-redux';
import TimelineItem, { Props } from '../components/TimelineItem';
import { getOrganisationName, isLoginDisabled, isSingleUser } from '../store/helpers';
import { actions, ReduxPropsType, StoreState } from '../store/utils';


const withRedux = connect((state: StoreState, ownProps: Props) => ({
   isSingle: isSingleUser(state),
   myself: state.login.data && state.login.data.myself,
   maxEarlyStartMinutes: state.config.maxEarlyStartMinutes,
   loginDisabled: isLoginDisabled(state),
   adhocOrganisationName: getOrganisationName(state, ownProps.meeting.adhocOrganisationId)

   
}), dispatch => ({ dispatch }), ({ isSingle, myself, ...props }, { dispatch }, { onExpand, needsForceStart, ...ownProps }: Props) => ({
   ...props,
   ...ownProps,
   onExpand,
   onEdit: (id: string) => {
      dispatch(actions.editBooking(id, undefined))
   },
   onDelete: (meetingId: string) => {
      onExpand()
      dispatch(actions.openPopupWithParams({ type: 'cancelMeeting', meetingId }))
   },
   onExtend: (id: string, hr: number) => {
      onExpand()
      dispatch(actions.extendMeeting(id, hr));
   },
   onStart: (meetingId: string) => {
      if (isSingle || !myself) { return }
      if (needsForceStart) {
         dispatch(actions.openPopupWithParams({ type: 'startMeeting', meetingId }))
      } else {
         dispatch(actions.startMeeting(meetingId, myself))
      }
      onExpand()
   },
   onLeave: (id: string) => {
      if (isSingle || !myself) { return }
      dispatch(actions.leaveMeeting(id, myself))
      onExpand()
   },
}));
export type TimelineItemProps = ReduxPropsType<typeof withRedux>;
export default withRedux(TimelineItem);
