import { getType } from "typesafe-actions";
import { Actions, StoreState, actions } from "../utils";

export type Theme = 'default' | 'red'| 'red2'| 'blue'| 'darkblue'| 'smokeblue'| 'gold'| 'green'| 'orange'| 'teal'| 'violet'| 'lime';

export const defaultAchieve = {
   theme: 'default' as Theme,
   dark: false
}

export default function achieve(state = defaultAchieve, action: Actions) {
   if (action.type === getType(actions.setAchieve)) {
     return action.payload
   }
   return state
 }

export function selectAchieve(state: StoreState) { return state.achieve }


